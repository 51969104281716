import "./App.css";

function Privacy() {
  return (
    <div className="background-colour">
      <div className="App text-canvas font-DMSans container">
        <div className="text-container justify">
        <h1>Privacy Policy</h1>
        <h2>Who we are</h2>
        Our website address is: 
         <a href="https://www.landofwarfare.com">
           &nbsp; https://www.landofwarfare.com
        </a>
        <h2>What personal data we collect and why we collect it:</h2>
        <h3>Comments</h3> When visitors leave comments on the site we collect
        the data shown in the comments form, and also
         the visitor’s IP address and browser user agent string to help
        spam detection. 
        
        If you leave a comment on our site you may opt in to
        saving your name, email address and website in cookies.
         These are for your convenience so that you do not have to fill in
        your details again when you leave another comment. 
        These cookies will last for one year. If you visit our login page, we
        will set a temporary cookie to determine if your browser accepts
        cookies.
         This cookie contains no personal data and is discarded when you
        close your browser.
        
        
        <h3>Embedded content from other websites</h3>
        Articles on this site may include embedded content (e.g. videos, images,
        articles, etc.). 
        
        Embedded content from other websites behaves in the exact same way as if
        the visitor has visited the other website.
         These websites may collect data about you, use cookies, embed
        additional third-party tracking, and monitor your interaction with that
        embedded content.  Including tracking your interaction with the
        embedded content if you have an account and are logged in to that
        website.
        
        
        <h3>Analytics</h3> Like many site operators, we collect information that
        your browser sends whenever you visit our Site ("Log Data"). 
        This Log Data may include information such as your computer's Internet
        Protocol ("IP") address, 
        browser type, browser version, the pages of our website that you visit,
        the time and date of your visit, the time spent on those pages and other
        statistics.
         In addition, we may use third party services such as Google
        Analytics that collect, monitor and analyze this.
        
        
        Google Analytics processes information about:
        <br/>
        <br/>
        
        <li>the pages you visit on our website</li>
        <li>how long you spend on each page</li>
        <li>how you got to the site</li>
        <li>what you click on while you’re visiting the site.</li>
        
        
        <h3>How long we retain your data </h3>If you leave a comment, the
        comment and its metadata are retained indefinitely.
         This is so we can recognise and approve any follow-up comments
        automatically instead of holding them in a moderation queue. 
        For users that register on our website (if any), we also store the
        personal information they provide in their user profile.
        
        All users can see, edit, or delete their personal information at any
        time (except they cannot change their username). 
        Website administrators can also see and edit that information.
        
        
        <h3>What rights you have over your data</h3> If you have an account on
        this site, or have left comments, you can request to receive an exported
        
        file of the personal data we hold about you, including any data you have
        provided to us.
         You can also request that we erase any personal data we hold
        about you.
         This does not include any data we are obliged to keep for
        administrative, legal, or security purposes.
        
        
        <h3>Where we send your data</h3>
        Visitor comments may be checked through an automated spam detection
        service.
        
        
        <h2>About our App</h2> <h3>Privacy Policy</h3>
        Ben Olney built the Land of Warfare - Battle Maps app as a paid app.
         This SERVICE is provided by Ben Olney and is intended for use as
        is.
         This page is used to inform visitors regarding my policies with
        the collection, use, <br/>and disclosure of Personal Information if anyone
        decided to use my Service.
         If you choose to use my Service, then you agree to the collection
        and use of information in relation to this policy.
         The Personal Information that I collect is used for providing and
        improving the Service. I will not use or share your information
        with anyone except as described in this Privacy Policy.<br/> The terms used
        in this Privacy Policy have the same meanings as in any Terms and
        Conditions,
        
        which may be accessible on this website unless otherwise defined in this
        Privacy Policy.
        
        
        <h3>Information Collection and Use</h3>
        For a better experience, while using our Service, I may require you to
        provide us with certain personally identifiable information,
         including but not limited to IP address, browser type and your
        general location as defined by your ISP. 
        The information that I request will be retained on your device and is
        not collected by me in any way.
         The app does use third party services that may collect
        information used to identify you. Third party service providers used by
        the app:
        <br/>
        <br/>
        <li>Apple Store</li>
        <li>Unity</li>
        
        <h3>Log Data</h3>I want to inform you that whenever you use my Service,{" "}
        
        in a case of an error in the app I collect data and information (through
        third party products) on your phone called Log Data.
         This Log Data may include information such as your device
        Internet Protocol (“IP”) address, device name, operating system version,
        the configuration of the app 
        when utilizing my Service, the time and date of your use of the Service,
        and other statistics.
        
        
        <h3>Cookies</h3> Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. 
        These are sent to your browser from the websites that you visit and are
        stored on your device’s internal memory. 
        This Service does not use these “cookies” explicitly.
        
        However, the app may use third party code and libraries that use
        “cookies” to collect information and improve services. 
        You have the option to either accept or refuse these cookies and know
        when a cookie is being sent to your device. 
        If you choose to refuse our cookies, you may not be able to use some
        portions of this Service.
        
        
        <h3>Service Providers</h3> In the future, I may employ third-party
        companies and individuals due to the following reasons:
        <br/><br/>
        
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services;</li>
        <li>To assist us in analyzing how our Service is used.</li>
        <br/><br/>
        
        I want to inform users of this Service that these third parties may then
        have access to your Personal Information.
        
        The reason is to perform the tasks assigned to them on our behalf.
        However, they are obligated not to disclose or use the information for
        any other purpose.
        
        
        <h3>Security</h3>
        I value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it.
         But remember that no method of transmission over the internet, or
        method of electronic storage is 100% secure and reliable, 
        and I cannot guarantee its absolute security.
        
        
        <h3>Links to Other Sites</h3>
        This Service may contain links to other sites.
         If you click on a third-party link, you will be directed to that
        site.
         Note that these external sites are not operated by me. 
        Therefore, I strongly advise you to review the Privacy Policy of these
        websites. I have no control over and assume no responsibility for
        the content, privacy policies, or practices of any third-party sites or
        services.
        
        
        <h3>Children’s Privacy</h3>
        These Services do not address anyone under the age of 13.
         I do not knowingly collect personally identifiable information
        from children under 13.
         In the case I discover that a child under 13 has provided me with
        personal information, I immediately delete this from our servers.
         If you are a parent or guardian and you are aware that your child
        has provided us with personal information, please contact me so that I
        will be able to do necessary actions.
        
        
        <h3>Changes to This Privacy Policy</h3>I may update our Privacy Policy
        from time to time.
         Thus, you are advised to review this page periodically for any
        changes.
         I will notify you of any changes by posting the new Privacy
        Policy on this page.
         This policy is effective as of 08/04/2022 (British Date Format).
         Contact Us If you have any questions or suggestions about this
        Privacy Policy at: shibameetsworld@gmail.com.
      </div>
    </div>
    </div>
  );
}

export default Privacy;
