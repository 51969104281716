import Link from "@material-ui/core/Link";

function Training() {
	return (
		<>
			<div className="App text-canvas font-low">
				<h1>
					<u>Commander Training</u>
				</h1>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>#1 Basic Training</h2>
					<p>
						Welcome Commander. Learn the basics of setting up a game and your
						base:
					</p>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/aOe5e17OBck"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>#2 Attack / Defence</h2>
					<p>
						Welcome Commander. Learn the basics of attack and defence against
						your opponents.
					</p>
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/bNSOF0LGukA"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
			<div>&nbsp;</div>
		</>
	);
}
export default Training;
