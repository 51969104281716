import MacAppStoreWhite from "./MacAppStoreWhite";
import Link from "@material-ui/core/Link";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import twitter from "./images/twitter.png";

function Development() {
	return (
		<>
			<div className="App text-canvas font-low">
				<h1>
					<u>News / Development Blog</u>
				</h1>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>01/01/2023</h2>
					<h3>New Unit - Heavy Tank</h3>
					<p>
					It's been long overdue but here is the first of the new units to join the game located in the War Factory. Heavy tanks provide great firepower and resistance in support of base attacks but they are slow to manoeuvre, so make sure you plan the timing and direction of your attacks well.
					<p>Other Updates - 
					More gameplay tweaks and a new design for the machine gun turret.</p>
					</p>
					<p>👇 Remember that we have some training videos if you get stuck, here on this link below</p>
					<NavLink to="/training" activeStyle>
						Training
					</NavLink>
					<h3>Get in Touch!</h3>
					<p>We have fired up our Twitter page so please hit us up if you have any feedback - we have actually tweaked our games from your comments and suggestions!</p>
					<a href="https://twitter.com/landofwarfare" target="_blank">
          <img src={twitter} alt="Our Twitter" width="50" height="40"/></a>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>24/12/2022</h2>
					<h3>Boom! We end 2022 with a big update!</h3>
					<p>
					New Map - Forest Land!
					Immerse yourself in a battle amongst the trees. All out warfare with evenly split resources. This new map is set to become a firm favourite as the shadows of the trees provide a more vivid battle field environment. Up to 5 teams on this map as well!
					</p>
				    <p>A complete rework of the battle field balance! Turrets are no longer the solution to an easy defence. Likewise, tanks have had a reduction in health too. In addition to this, all units have been tweaked to provide an improved gameplay experience. This will make you focus more on strategy to gain the upper hand, especially when facing multiple opponents.</p>
					<p>👇 Remember that we have some training videos if you get stuck, here on this link below</p>
					<NavLink to="/training" activeStyle>
						Training
					</NavLink>
					<h3>Get in Touch!</h3>
					<p>We have fired up our Twitter page so please hit us up if you have any feedback - we have actually tweaked our games from your comments and suggestions!</p>
					<a href="https://twitter.com/landofwarfare" target="_blank">
          <img src={twitter} alt="Our Twitter" width="50" height="40"/></a>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>26/10/2022</h2>
					<h3>New Map Added!</h3>
					<p>
					New Map - Snow Lake!
					Snow lake will test your resource collection priorities and attacking strategy. 
					Certain resources are weighted more heavily to one side of the bridge than the other. 
					This will require a strategy rethink each time you are spawned on a different side!
					We also adjusted the damage that airstrikes from fighter jets can deal to units and buildings. 
					Units directly take more damage and collective units within a surrounding area of the blast. 
					Buildings in the surrounding area of a blast impact will now take slightly more damage.
					</p>
					<p>👇 Remember that we have some training videos if you get stuck, here on this link below</p>
					<NavLink to="/training" activeStyle>
						Training
					</NavLink>
					<p></p>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>12/07/2022</h2>
					<h3>New Map Added!</h3>
					<p>
						New Map - Rough Flats! This new map will test your ability to move
						fast and deny your opponents the opportunity to access key
						resources. If you control the abandoned houses you will control the
						wealth. Controlling the wealth means that your opponents won't be
						able to afford more powerful units and personnel. The more factions
						you compete against though, the more difficult that challenge will
						be!
					</p>
					<p>Further training is now also available on Attack & Defence:</p>
					<NavLink to="/training" activeStyle>
						Training
					</NavLink>
					<p></p>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>04/05/2022</h2>
					<h3>Commander Training</h3>
					<p>Welcome Commander! Training camp is now open:</p>
					<NavLink to="/training" activeStyle>
						Training
					</NavLink>
					<p>
						We've added a new page for commander training camp. The first video
						up is Basic Training. More videos will be added in due course, sir.
					</p>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>10/04/2022</h2>
					<h3>New Map Added!</h3>
					<p>
						We have added a new map as promised called 'Shipwreck Coast'. Can
						you control the shipwrecked treasure and cut off your opponent's
						resources? Check out our new channel on{" "}
						<a href="https://www.twitch.tv/landofwarfare">Twitch</a>!
					</p>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>08/04/2022</h2>
					<h3>Land of Warfare has now landed!</h3>
					<p>
						Out now exclusively on Mac App Store! We're so excited to finally
						bring you the game. We can also reveal we are testing a new map to
						be released very soon called 'Shipwreck Coast'. The new map will
						test your game strategy skills, cutting off certain supplies to
						bridge routes only. Control the bridges. Find the old shipwrecked
						boat and control access to the shipwrecked treasure! Voting to start
						soon on new game features and map types.
					</p>
					<Link href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1615110859">
						<MacAppStoreWhite />
					</Link>
				</div>
			</div>
			<div>&nbsp;</div>
			<div className="App text-canvas font-DMSans container">
				<div className="text-container justify">
					<h2>01/04/2022</h2>
					<h3>Are you Ready for Launch Commmander?</h3>
					<p>
						We are pleased to announce that Land of Warfare will be released on
						08/04/22. We are currently working on new maps and NPC improvements
						but you will have a chance to vote on the new features right here
						very soon. Check out the long gameplay trailer on the home page!
					</p>
				</div>
			</div>
			<div>&nbsp;</div>
		</>
	);
}
export default Development;
