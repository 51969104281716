import React, { useState } from 'react';

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: 'support',
    comments: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct mailto link
    const recipient = "contact@landofwarfare.com";
    const subject = encodeURIComponent(`Contact Form Submission: ${formData.subject}`);
    const body = encodeURIComponent(
      `Name: ${formData.name}\n` +
      `Email: ${formData.email}\n` +
      `Subject: ${formData.subject}\n` +
      `Comments:\n${formData.comments}`
    );
    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

    // Open mail client
    window.location.href = mailtoLink;

    console.log(formData);
    alert('We have created the email for you! Please now send this via your email provider.');
  };

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#00ffcd',
    fontFamily: 'DMSans-Regular, Arial, sans-serif',
    padding: '20px'
  };

  const fieldContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
    width: '100%'
  };

  const labelStyle = {
    marginBottom: '5px'
  };

  const inputStyle = {
    width: '100%', // Ensures input fields use full width of the container
    borderRadius: '5px',
    border: '1px solid #00ffcd',
    padding: '10px',
    color: '#00ffcd',
    backgroundColor: 'transparent'
  };

  const buttonStyle = {
    border: '1px solid #00ffcd',
    borderRadius: '5px',
    padding: '10px 20px',
    color: '#00ffcd',
    backgroundColor: 'transparent',
    cursor: 'pointer'
  };

  return (
    <div className="text-title text-canvas">
    <form onSubmit={handleSubmit} style={formStyle}>
      <div style={fieldContainerStyle}>
        <label style={labelStyle}>Name:</label>
        <input 
          type="text" 
          name="name" 
          value={formData.name} 
          onChange={handleChange} 
          style={inputStyle}
        />
      </div>
      <div style={fieldContainerStyle}>
        <label style={labelStyle}>Email:</label>
        <input 
          type="email" 
          name="email" 
          value={formData.email} 
          onChange={handleChange} 
          style={inputStyle}
        />
      </div>
      <div style={fieldContainerStyle}>
        <label style={labelStyle}>Subject:</label>
        <select 
          name="subject" 
          value={formData.subject} 
          onChange={handleChange}
          style={inputStyle}
        >
          <option value="support">Support</option>
          <option value="media">Media</option>
          <option value="feature-requests">Feature Requests</option>
        </select>
      </div>
      <div style={fieldContainerStyle}>
        <label style={labelStyle}>Comments:</label>
        <textarea 
          name="comments" 
          value={formData.comments} 
          onChange={handleChange}
          style={{ ...inputStyle, height: '100px' }}
        />
      </div>
      <div style={fieldContainerStyle}>
        <button type="submit" style={buttonStyle}>Submit</button>
      </div>
    </form>
    </div>
  );
}

export default ContactForm;
