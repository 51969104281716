import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import twitter from "./images/twitter-black.png";

const Navbar = () => {
  return (
    <Nav className="Nav">
      <NavMenu className="NavMenu">
        <NavLink className="NavLink" to="/">
          Home
        </NavLink>
        <NavLink className="NavLink" to="/development">
          News
        </NavLink>
        <NavLink className="NavLink" to="/training">
          Training
        </NavLink>
        <NavLink className="NavLink" to="/contact">
          Contact Us
        </NavLink>
        <NavLink className="NavLink" to="/privacy">
          Privacy
        </NavLink>
        <a className="NavLink" href="https://twitter.com/landofwarfare" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="X" width="20" height="20"/>
        </a>
      </NavMenu>
    </Nav>
  );
};

export default Navbar;
