import game from "./images/high-res/land-of-warefare-background.png";
import gameaction from "./images/gameplay-action.jpg";
import base from "./images/base.jpg";
import resources from "./images/resources.jpg";
import turret from "./images/turret.jpg";
import ambush from "./images/ambush.jpg";
import ReactPlayer from "react-player";
import Video from "./videos/trailer.mp4";
import Poster from "./images/trailer-poster.jpg";
import Link from "@material-ui/core/Link";
import MacAppStore from "./MacAppStore";

function Home() {
  return (
    <div className="App font-low">
      <header className="App-header">
        <br />
        <img src={game} className="App-logo Pulse" alt="Land of Warfare" />
        <br />
        <div className="text-title text-canvas">
          <h2>Land of Warfare - Battle Maps</h2>
        </div>
        <div className="video-width">
          <br />
          <ReactPlayer
            url={Video}
            controls={true}
            playing
            light={Poster}
            width ="600px"
          />
        </div>
        <div>
          <br />
          <Link href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1615110859">
            <MacAppStore />
          </Link>
        </div>
        <div>
          <h2 className="text-canvas">Reporting for duty, Commander</h2>
          <img
            src={gameaction}
            className="App-gameplay"
            alt="Gameplay Skirmish"
          />
          <div>
            <h3 className="text-canvas">Battle Maps</h3>
            <p className="black-text">
              Commander, we fear that peace time is ending. our bases are set up
              in the Battle Lands and we need your leadership. Our enemies are
              increasing in size and power, we must fight for freedom.
            </p>
          </div>
          <br />
          <div>
            <h3 className="text-canvas">Base Construction</h3>
            <img src={base} className="App-gameplay" alt="Base Construction" />
            <p className="black-text">
              Base construction is vital to unlock new weapons and facilities.
            </p>
          </div>
          <br />
          <div>
            <h3 className="text-canvas">Base Defence</h3>
            <img
              src={turret}
              className="App-gameplay"
              alt="Base Construction"
            />
            <p className="black-text">
              The right armoury and personnel support can fend off even the most
              intense attacks.
            </p>
          </div>
          <br />
          <div>
            <h3 className="text-canvas">Resources</h3>
            <img
              src={resources}
              className="App-gameplay"
              alt="Gather Resources"
            />
            <p className="black-text">
              The battle lands are abundant with natural resources which we can
              use to increase our production and regiment capabilities.
            </p>
          </div>
          <br />
          <div>
            <h3 className="text-canvas">Strategy</h3>
            <img src={ambush} className="App-gameplay" alt="Gather Resources" />
            <p className="black-text">
              When preparing your troops for battle, you must decide on the best strategy to defeat your opponents.
            </p>
          </div>
          <div>
            <h2 className="text-canvas">Are you ready, Commander?</h2>
          </div>
        </div>
        <div>
          <br />
          <Link href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1615110859">
            <MacAppStore />
          </Link>
        </div>
        <br />
        <br />
      </header>
    </div>
  );
}

export default Home;
